@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


html {
  background-color: #121212;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
}

:root {
  --bg-color: #121212;
}

.ant-typography {
  color: white;
}

.ant-layout {
  min-height: 100vh;
  width: 100vw;
  background-color: #121212;
}

.IntroductionText {
  font-size: 3vh;
  font-weight: 400;
  margin: 2vh 1vw;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
}

.ant-typography a {
  color : white;
  text-decoration: none;
}

.ant-typography a:hover {
  color : #ffffff;
  text-decoration: none;
}

.ant-typography mark {
  background-color: rgba(255, 255, 255, 0.25);
}

.social {
  color: white;
  font-size: 2.5vh;
  margin: 2vh 15px;
}

.social:hover{
  color: #ffffff;
}

.ant-layout-header {
  background: #121212;
  background: var(--bg-color);
  color : white;
  height: 5vh;
  width: 100vw;
}

.ant-layout-content {
  background: #121212;
  background: var(--bg-color);
  color : white;
  text-align : 'middle';
  margin-left: 20px;
}

.ant-layout-footer {
  text-align : 'middle';
  background: #121212;
  background: var(--bg-color);
  color : white;
  width: 100vw;
}

